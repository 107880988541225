@import 'src/styles/mixins';
@import 'src/styles/variables';

.subMenuContainer {
    transition: max-height 0.3s $cubic;
    cursor: default;

    position: relative;
    top: 100%;
    left: -24px;
    display: flex;
    width: calc(100% + 24px + 16px);
    flex-direction: column;
    background-color: rgba(247, 248, 252, 0.5);
    overflow: hidden;
}

.mainTitle {
    @include osNormal;
    font-size: 14px;
    line-height: 140%;
    text-transform: capitalize;
    color: $blueForLight;

    display: none;
}

:global(.ant-layout-sider-collapsed.sidebar) {
    .mainTitle {
        display: block;
    }
}

:global(.ant-layout-sider-collapsed.sidebar .ant-menu-item.sidebarSubMenu) {
    color: transparent !important;
}

:global(.ant-layout-sider-collapsed.sidebar
        .ant-menu-item.ant-menu-item-selected.sidebarSubMenu
        .ant-menu-title-content) {
    opacity: 1 !important;
    //transition: opacity .3s $cubic !important;
    //color: transparent !important;
}

:global(.ant-menu-item.ant-menu-item-selected.sidebarSubMenu .subMenuContainer) {
    visibility: visible;
}

:global(.ant-layout-sider-collapsed.sidebar) {
    .subMenuContainer {
        visibility: hidden;
        max-height: fit-content !important;
        min-width: 240px;
        position: absolute;
        top: 0;
        left: 100%;
        border-radius: 0 8px 8px 0;
        background-color: #f8f8f8;
        display: flex;
        flex-direction: column;
        // padding: 12px 20px 8px;
        padding-bottom: 10px;
    }

    .mainTitle {
        // padding-bottom: 12px;

        padding: 15px 0 14px 20px;
    }
}
