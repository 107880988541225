@import '../../../styles/variables';

.modalWrapper {
    margin: auto;
    top: 0;
    padding-bottom: 0;
    font-family: 'Open Sans';
    font-weight: 400;
}

.modalFooter {
    display: flex;

    button {
        width: 160px;
        display: flex;
        margin-top: 0 !important;
        border-radius: 4px !important;
        text-transform: uppercase;

        &:last-child {
            margin-left: auto;
        }

        span {
            margin: auto;
        }
    }
}

.header {
    display: flex;
    margin-bottom: 34px;

    .title {
        font-size: 18px;
        margin-bottom: 0;
    }

    .closeBtn {
        margin-left: auto;
        border: none;
        font-size: 18px;
    }
}

.files {
    display: grid;
    flex-wrap: wrap;
    margin-bottom: 27px;
    min-height: 120px;

    .file {
        height: 46px;
        display: flex;
        border: none;
        border-radius: 8px;
        background: #eeeff4;
        margin-bottom: 14px;
        margin-right: auto;

        span {
            margin: auto;
            padding-right: 11px;
            font-family: 'Open Sans';
            font-size: 14px;
            color: #7a7b84;
            white-space: break-spaces;
            max-width: 300px;
            max-height: 40px;
            overflow: hidden;
        }

        span:first-child {
            margin: auto;
            padding-right: 8px;
            font-size: 30px;
        }

        span:last-child {
            margin: auto;
            padding-right: 4px;
        }
    }
}
