@import 'src/styles/variables';

.layout {
    background-image: url('img/authBg.svg');
    width: 100%;
    height: 100vh;
    display: flex;
    position: relative;
    bottom: 58px;
    padding-top: 58px;
    background-size: initial;
    overflow-y: scroll;
    background-repeat: no-repeat;
    background-color: $white;
    background-position: center center;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
}

.content {
    margin: auto;
    width: 580px;
    border-radius: 12px;
    box-shadow: 0 0 8px 0 #00000029;
    align-items: center;
    flex-flow: column;
    display: flex;
    padding: 0 60px 30px;
    background-color: $white;
    height: max-content;

    .header {
        margin-top: 36px;
        width: 100%;
        display: flex;

        &__left {
            height: 57px;
            font-size: 130px;
            margin-left: 17px;
            align-self: center;

            svg {
                width: 140px;
                height: 57px;
            }
        }

        &__right {
            margin-left: auto;
            font-size: 70px;
            align-self: center;
            margin-right: 24px;
        }
    }

    &__logo {
        margin-top: 25px;
        font-size: 200px;
        height: 60px;

        p {
            text-transform: uppercase;
        }

        p:first-child {
            font-family: 'TT Bricks';
            font-size: 24px;
            margin-bottom: 0;
            height: 35px;
        }

        p:last-child {
            font-family: 'Open Sans', sans-serif, Verdana, Geneva, Tahoma;
            font-size: 15px;
            font-weight: 800;
        }
    }
}

.formContainer {
    width: 100%;
}
