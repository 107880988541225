@import './src/styles/variables';

.container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.navLink {
    color: $grayDarkColor;
    text-align: center;
    text-decoration: none;
    padding: 0 30px 6px;
    font-size: 16px;
    font-weight: 400;
    width: 50%;
    border-bottom: 2px solid $grayLight;
    &__active {
        color: $blueForLight;
        border-bottom: 1px solid $blueForLight;
    }
}
