@import 'src/styles/variables';

:global(.MainPageOverlay) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -2;
    transition: background-color 0.3s $cubic, z-index 0s $cubic 0.3s;

    &_active {
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 0;

        transition: background-color 0.3s $cubic, z-index 0s $cubic;
        display: none;
    }
}

:global(.ant-layout-sider-collapsed.sidebar ~ .ant-layout .MainPageOverlay) {
    &_active {
        display: block;
    }
}
