@mixin hover {
    &:not([disabled]):hover {
        @content;
    }
}

@mixin active {
    &:not([disabled]):active {
        @content;
    }
}

@mixin osBold {
    font-family: 'Open Sans', sans-serif, Verdana, Geneva, Tahoma;
    font-style: normal;
    font-weight: 700;
}

@mixin osNormal {
    font-family: 'Open Sans', sans-serif, Verdana, Geneva, Tahoma;
    font-style: normal;
    font-weight: 400;
}

@mixin osLight {
    font-family: 'Open Sans', sans-serif, Verdana, Geneva, Tahoma;
    font-style: normal;
    font-weight: 300;
}

@mixin interNormal {
    font-family: 'Inter', Verdana, Geneva, Tahoma, sans-serif;
    font-style: normal;
    font-weight: 400;
}
