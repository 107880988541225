@import 'src/styles/variables';
@import 'src/styles/mixins';

.arrowWrapper {
    transition: transform 0.3s $cubic, opacity 0.2s $cubic;
    position: absolute;
    right: 16px;
    top: 18px;
    display: block;

    width: 12px;
    height: 12px;

    svg {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;

        path {
            stroke: $grayDark2;
            transition: stroke 0.3s $cubic;
        }
    }

    &_rotate {
        transform: rotate(180deg);

        svg {
            path {
                stroke: $blueForLight;
            }
        }
    }
}

:global(.ant-layout-sider-collapsed.sidebar) {
    :global(.sidebarSubMenu) {
        // display: flex !important;

        :global(.sidebarSubMenu__icon) {
            min-height: 24px;
            min-width: 24px;
        }
    }

    .arrowWrapper {
        opacity: 0;
    }
}

:global(.sidebarSubMenu) {
    padding-left: 16px !important;

    @include osNormal;
    font-size: 14px;
    line-height: 140%;
}
