@import '../../../styles/variables';
@import '../../../styles/mixins';

.content {
    padding-left: 35px;
    padding-right: 34px;
    font-family: 'Open Sans' !important;
}

.header {
    display: flex;
    margin: 24px 40px 0;

    .title {
        font-size: 18px;
        margin-bottom: 0;
    }

    .closeBtn {
        margin-left: auto;
        border: none;
        font-size: 18px;
    }
}

.actions {
    font-family: 'Open Sans', sans-serif, Verdana, Geneva, Tahoma;
}

.tableWrapper {
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    form {
        padding: 16px 25px 16px 25px;

        .antSelect {
            height: 46px;
            display: flex;

            div:first-child {
                height: 46px !important;
                border: 1px solid #d9d9d9 !important;
                border-radius: 4px;

                @include hover {
                    border: 1px solid #40a9ff !important;
                }

                input {
                    height: 46px !important;
                }
            }
        }

        .antInput {
            height: 46px;
            display: flex;
            border-radius: 4px;
        }

        label {
            span {
                display: flex;
                span {
                    display: flex;
                    margin: auto;
                }
            }
        }

        .createBtn {
            min-width: 112px;
        }

        .copyBtn {
            min-width: 99px;
        }

        .editBtn {
            min-width: 159px;
        }

        .deleteBtn {
            min-width: 113px;
        }

        .revokeBtn {
            min-width: 125px;
        }

        .exportBtn {
            min-width: 117px;
        }

        .filter {
            color: #363740;
            padding-right: 8px;
            display: flex;
            align-items: center;
            margin-left: auto;
        }

        .datePicker {
            padding: 16px 8px 16px 8px;

            div:first-child {
                border-radius: 4px 0 0 4px;
            }

            div:last-child {
                border-radius: 0 4px 4px 0;
            }
        }
    }

    table {
        thead {
            th {
                font-weight: bold;
            }

            th:first-child {
                padding: 10px 10px 10px 25px;
            }

            th::before {
                background-color: #fafafa !important;
            }

            th:last-child {
                padding-right: 25px;
            }
        }

        tbody {
            tr {
                td {
                    padding: 10px;
                }

                td:first-child {
                    padding: 10px 10px 10px 25px;
                }

                td:last-child {
                    padding-right: 25px;
                }
            }
        }
    }

    ul {
        position: absolute;

        li {
            margin: 0;
            border-radius: 0;
            border: 0;
            border-top: 1px solid #dfe0eb;
            border-right: 1px solid #dfe0eb;
            border-bottom: 1px solid #dfe0eb;
            background: none;

            @include hover {
                border-top: 1px solid #dfe0eb;
                border-right: 1px solid #dfe0eb;
                border-bottom: 1px solid #dfe0eb;
            }
        }

        li:first-child {
            border: 1px solid #dfe0eb;
            border-radius: 4px 0 0 4px !important;
            width: 38px !important;

            button {
                border: 0;
                background: none;
            }

            @include hover {
                border-top: 1px solid #dfe0eb;
                border-left: 1px solid #dfe0eb;
                border-bottom: 1px solid #dfe0eb;
            }
        }

        li:last-child {
            border-top: 1px solid #dfe0eb;
            border-right: 1px solid #dfe0eb;
            border-bottom: 1px solid #dfe0eb;
            border-radius: 0 4px 4px 0 !important;
            width: 38px !important;

            button {
                border: 0;
                background: none;
            }

            @include hover {
                border-top: 1px solid #dfe0eb;
                border-right: 1px solid #dfe0eb;
                border-bottom: 1px solid #dfe0eb;
            }
        }
    }
}

.filesWrapper {
    display: flex;
    position: fixed;
    bottom: 32px;
}

.icon {
    margin-right: 11px;
}

.buttons {
    min-width: 100%;
    padding: 16px 0 16px 8px;
    display: flex;

    label:first-child {
        margin-left: auto;
        border-radius: 4px 0px 0px 4px !important;
    }

    :last-child {
        border-radius: 0px 4px 4px 0px !important;
    }
}

.button {
    border-radius: 4px !important;
    border: 1px solid #dfe0eb;
}

.title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #7a7b84;

    span:last-child {
        span {
            font-weight: bold;
            color: black;
        }
    }
}

.status {
    display: flex;
    min-height: 40px;
    margin-left: auto;
    padding-left: 12px;
    padding-right: 12px;

    span {
        margin: auto;
    }
}

.testmodalSelect {
    width: 100%;
    height: 48px;
    display: flex;

    div:first-child {
        height: 48px !important;
        border: 1px solid #d9d9d9 !important;
        border-radius: 4px;

        @include hover {
            border: 1px solid #40a9ff !important;
        }

        input {
            height: 48px !important;
        }
    }
}

.modalSelect {
    padding: 12px 8px !important;
    width: 100% !important;
    border: 1px solid $grayBorderFields;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    &::-moz-placeholder {
        color: $grayDark;
    }
    &::-webkit-input-placeholder {
        color: $grayDark;
    }

    border-radius: 4px;
    position: relative;
    &:focus {
        border-radius: 2pt;
        box-shadow: 0 0 0 1pt $blueForLight;
        outline: none;
        transition: 0.1s;
    }
}

.modalFooter {
    display: flex;
    margin-right: 40px;
    padding-bottom: 16px;

    button {
        width: 160px;
        margin-left: auto;
        display: flex;
        margin-top: 0 !important;
        border-radius: 4px !important;
        text-transform: uppercase;

        span {
            margin: auto;
        }
    }
}

.fullWidthInput {
    width: 100% !important;
    border-radius: 4px !important;
    color: #000000d9;
}

.numberInput {
    padding: 8px !important;

    input {
        padding: 0 11px 0 0;
    }
}

.modalWrapper {
    margin: auto;
    top: 0;
    padding-bottom: 0;
}
