@import './variables';
.btn {
    border: 0;
    outline: 0;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    padding: 12px !important;
    border-radius: 4px !important;
    font-weight: 600;
    height: auto !important;
    letter-spacing: 1.5px;
    font-family: 'Open Sans', sans-serif;
    transition: all 0.2s;
    &--w100 {
        width: 100%;
    }
}

.btn-blue {
    background-color: $blueForLight !important;
    border-color: $grayBorderFields !important;
    color: $white !important;
    font-weight: 400 !important;
    &::before {
        background-color: $grayBorderFields !important;
    }
    &:hover {
        background-color: $blueForFocus !important;
    }
    &:focus {
        background-color: $blueForFocus !important;
    }
    &:active {
        background-color: $blueForActive !important;
    }
    &:disabled {
        background-color: $grayBorderFields !important;
        color: #00000040 !important;
    }

    &.ant-radio-button-wrapper-disabled {
        color: rgba(0, 0, 0, 0.25) !important;
        background-color: #e6e6e6 !important;
        border-color: $grayBorderFields !important;
        box-shadow: none !important;
    }
}

.btn-white {
    background-color: $white !important;
    color: $grayDarkColor !important;
    border: 1px solid $grayBorderFields !important;
    &:hover {
        background-color: $grayLight !important;
    }
    &:focus {
        background-color: $grayLight !important;
    }
    &:active {
        background-color: $grayLight !important;
    }
}

.btn-red {
    background-color: $redForLight !important;
    border-color: $grayBorderFields !important;
    color: $white !important;
    font-weight: 400 !important;

    &::before {
        background-color: $grayBorderFields !important;
    }

    &:hover {
        background-color: $redForFocus !important;
    }

    &:focus {
        background-color: $redForFocus !important;
    }

    &:active {
        background-color: $redForActive !important;
    }

    &:disabled {
        background-color: $grayBorderFields !important;
        color: #00000040;
    }
}
