aside {
    z-index: 1000 !important;
}

.MainPageOverlay {
    &.MainPageOverlay_active {
        z-index: 999 !important;
    }
}

.sidebarMenuItem {
    position: relative;
    min-height: 48px;

    &::after {
        left: 0;
        right: auto !important;
        border-right: 5px solid $blueForLight !important;
    }
}

.sidebarSubMenu {
    min-height: 48px;
    position: relative;
    align-content: center;
    z-index: 1;

    &::after {
        left: 0;
        right: auto !important;
        border-right: 5px solid $blueForLight !important;
        max-height: 48px;
    }

    &.ant-menu-item-selected {
        &::after {
            transform: scaleY(1) !important;
            opacity: 1 !important;
        }
    }
}

.ant-menu-item-selected {
    color: $blueForLight !important;
}

.ant-menu-inline-collapsed {
    .ant-menu-item.sidebarMenuItem {
        padding: 0 calc(50% - 22px / 2) !important;
    }

    .ant-menu-item.sidebarSubMenu {
        padding: 3px calc(50% - 22px / 2) 0 !important;
    }
}

.ant-menu-vertical.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-left.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-right.ant-menu-sub:not([class*='-active']) {
    border-radius: 5px;
    background-color: rgba(247, 248, 252, 0.5);
}

.sidebarMenuItem.ant-menu-item-selected {
    &::after {
        opacity: 1 !important;
        transform: scaleY(1) !important;
    }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $grayLight !important;
}

.ant-menu-item.sidebarMenuItem:hover {
    color: $grayDark !important;
}
.ant-menu-item.sidebarSubMenu:hover {
    color: $grayDark !important;
}

.ant-menu-item.ant-menu-item-selected.sidebarMenuItem {
    color: $blueForLight !important;
}
.ant-menu-item.ant-menu-item-selected.sidebarSubMenu {
    color: $blueForLight !important;
}

.ant-menu-item.ant-menu-item-selected.sidebarMenuItem:hover {
    color: $blueForLight !important;
}
.ant-menu-item.ant-menu-item-selected.sidebarSubMenu:hover {
    color: $blueForLight !important;
}

.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
    border: none;
}

.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
    border: none;
}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    width: 100%;
}

.ant-layout-header {
    line-height: 0 !important;
}

.ant-scroll-number-only {
    line-height: 13px;
    left: 0.43px;
}

.ant-menu-submenu-selected {
    color: $blueForLight !important;
}

.mainPageGlobal .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
    margin-left: 10px;
}

.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: $blueForLight !important;
}

.ant-menu-item.ant-menu-item-selected.sidebarMenuItem img {
    filter: invert(42%) sepia(68%) saturate(674%) hue-rotate(193deg) brightness(96%) contrast(102%);
}

.ant-menu-item.ant-menu-item-selected.sidebarSubMenu img {
    filter: invert(42%) sepia(68%) saturate(674%) hue-rotate(193deg) brightness(96%) contrast(102%);
}

.ant-menu-item.sidebarSubMenu .ant-menu-title-content {
    vertical-align: middle;
}

.ant-modal-wrap {
    display: flex;
    align-content: center;

    .ant-modal-content {
        border-radius: 4px;
    }
}

.ant-switch-checked {
    background-color: $blueForLight !important;
}

.ant-btn-primary {
    border-color: #1268b3 !important;
    background: #1268b3 !important;
}

.ant-btn-link {
    color: #1268b3 !important;
}

.unfilled {
    svg {
        fill: none;
    }
}
