@import '../../styles/variables';

#float-label {
    display: flex;
    flex-direction: column;
    //min-width: 350px;
    position: relative;
    /*overflow: hidden;*/
    transition: all 0.3s;

    input[type='number'] {
        -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
}

#float-label input:not(.ant-select-selection-search-input),
textarea {
    width: 100%;
    height: 46px;
    padding: 14px 16px 0 10px;
    outline: 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #fff;
    font-size: 14px;
    transition: background-color 1s, border-color 1s, box-shadow 0.3s;
}

#float-label {
    .ant-select-selector {
        width: 100%;
        height: 46px;
        padding: 14px 16px 0 10px;
        outline: 0;
        border: 1px solid #ddd !important;
        border-radius: 4px;
        background: #fff;
        font-size: 14px;
        color: inherit !important;
        transition: background-color 1s, border-color 1s, box-shadow 0.3s;

        .ant-select-selection-overflow {
            position: absolute;
        }

        .ant-select-selection-search {
            input {
                width: 100%;
                height: 45px;
                /*padding: 14px 16px 0 0;*/
                outline: 0;
                font-size: 14px;
            }
        }
    }

    .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
        color: inherit !important;
    }

    .ant-select-selector:hover {
        border-color: #40a9ff !important;
    }
}

#float-label label {
    font-size: 14px;
    padding: 0 12px;
    color: #bfbfbf;
    pointer-events: none;
    position: absolute;
    white-space: nowrap;
    transform: translate(0, 12px) scale(1);
    transform-origin: top left;
    transition: all 0.2s ease-out;
    z-index: 99;
    &::-moz-placeholder {
        color: $grayDark;
    }
    &::-webkit-input-placeholder {
        color: $grayDark;
    }
}

#float-label:focus-within label {
    transform: translate(0, 0px) scale(0.75);
    color: #5172f6;
}

#float-label .Active {
    transform: translate(0, 0px) scale(0.75);
    z-index: 999;
}

input.Focused,
textarea.Focused {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

input:hover,
textarea:hover {
    border-color: #40a9ff !important;
}

.arrow {
    font-size: 10px;
    position: absolute;
    right: 12px;
    top: 20px;
}

.suffix {
    right: 12px;
    top: 12px;
    position: absolute;
}

#float-label {
    &.disabled {
        color: rgba(0, 0, 0, 0.25) !important;

        input {
            background: #f5f5f5 !important;
            cursor: not-allowed !important;
        }

        .ant-select-selector {
            overflow: hidden !important;
            background: #f5f5f5 !important;

            .ant-select-selection-item {
                color: rgba(0, 0, 0, 0.25) !important;

                span {
                    color: rgba(0, 0, 0, 0.25) !important;
                }
            }

            input {
                background: none;
            }
        }

        .arrow {
            visibility: hidden;
        }

        &:hover {
            border: #d9d9d9 !important;
        }
    }

    .ant-form-item-has-error {
        #float-label input,
        textarea {
            border-color: #ff4d4f !important;
        }

        input.Focused,
        textarea.Focused {
            border-color: #ff7875;
            box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
        }

        #float-label {
            .ant-select-selector {
                background-color: #fff;
                border-color: #ff4d4f !important;
            }

            .ant-select-selector:hover {
                border-color: #ff4d4f !important;
            }
        }
    }

    #float-label {
        .ant-select {
            z-index: 1;

            .ant-select-selector {
                background: #fff;
            }
        }
        span.ant-select-arrow {
            visibility: hidden !important;
        }
    }

    .multiline {
        .ant-select-selector {
            height: auto !important;
        }

        .ant-select-selection-overflow {
            position: relative !important;

            .ant-select-selection-search {
                input {
                    height: inherit !important;
                    min-height: 25px !important;
                }
            }

            &:after {
            }
        }
    }

    .selectAll {
        width: 90% !important;
    }

    .selectAllButton {
        width: 10% !important;
        height: 46px !important;
        margin-left: 8px;
        font-size: small !important;
    }

    .switcher {
        height: 46px;
        display: flex;
        align-items: center;

        span {
            margin-left: 16px;
        }
    }
}
