@import '../../styles/variables';
@import '../../styles/mixins';

.content {
    padding-left: 35px;
    padding-right: 34px;
    font-family: 'Open Sans' !important;
}

.header {
    padding: 32px 0 24px 0;
}

.tableWrapper {
    background: #ffffff;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    .icon {
        margin-right: 11px;
    }

    form {
        .antSelect {
            height: 32px;
            width: 350px;
            margin: 16px 0 16px 25px;

            .ant-select-selector {
                max-height: 32px !important;
            }

            div:first-child {
                height: 32px !important;
                border: 1px solid #d9d9d9 !important;
                border-radius: 4px;

                @include hover {
                    border: 1px solid #40a9ff !important;
                }

                input {
                    height: 32px !important;
                }
            }
        }

        label {
            span {
                display: flex;
                span {
                    display: flex;
                    margin: auto;
                }
            }
        }

        .createBtn {
            min-width: 112px;
        }

        .copyBtn {
            min-width: 99px;
        }

        .editBtn {
            min-width: 115px;
        }

        .deleteBtn {
            min-width: 113px;
        }

        .revokeBtn {
            min-width: 125px;
        }

        .exportBtn {
            min-width: 117px;
        }

        .space {
            display: flex;
            margin-left: auto;
        }

        .filter {
            padding-right: 8px;
            display: flex;
            align-items: center;
        }

        .datePicker {
            padding: 16px 0 16px 8px;
            margin-right: 25px;

            div:first-child {
                border-radius: 4px 0 0 4px;
            }

            div:last-child {
                border-radius: 0 4px 4px 0;
            }
        }
    }

    table {
        thead {
            th {
                font-weight: bold;
            }

            th:first-child {
                padding-left: 25px !important;
            }

            th::before {
                background-color: #fafafa !important;
            }
        }

        tbody {
            tr {
                td:first-child {
                    padding-left: 25px !important;
                }

                td:last-child {
                    padding-right: 25px !important;
                }
            }
        }
    }

    ul {
        position: absolute;
        width: 100%;

        li {
            margin: 0;
            border-radius: 0;
            border: 0;
            border-top: 1px solid #dfe0eb;
            border-right: 1px solid #dfe0eb;
            border-bottom: 1px solid #dfe0eb;
            background: none;

            @include hover {
                border-top: 1px solid #dfe0eb;
                border-right: 1px solid #dfe0eb;
                border-bottom: 1px solid #dfe0eb;
            }
        }

        li:first-child {
            border: 1px solid #dfe0eb;
            border-radius: 4px 0 0 4px !important;
            width: 38px !important;

            button {
                border: 0;
                background: none;
            }

            @include hover {
                border-top: 1px solid #dfe0eb;
                border-left: 1px solid #dfe0eb;
                border-bottom: 1px solid #dfe0eb;
            }
        }

        li:last-child {
            border-top: 1px solid #dfe0eb;
            border-right: 1px solid #dfe0eb;
            border-bottom: 1px solid #dfe0eb;
            border-radius: 0 4px 4px 0 !important;
            width: 38px !important;

            button {
                border: 0;
                background: none;
            }

            @include hover {
                border-top: 1px solid #dfe0eb;
                border-right: 1px solid #dfe0eb;
                border-bottom: 1px solid #dfe0eb;
            }
        }
    }
}

.buttons {
    min-width: 100%;
    padding: 16px 8px 16px 25px;

    label:first-child {
        border-radius: 4px 0 0 4px !important;
    }

    :last-child {
        border-radius: 0 4px 4px 0 !important;
    }
}

.button {
    border-radius: 4px !important;
    border: 1px solid #dfe0eb;
}

.actions {
    font-family: 'Open Sans', sans-serif, Verdana, Geneva, Tahoma;
}

.title {
    font-family: 'Open Sans', sans-serif, Verdana, Geneva, Tahoma;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #7a7b84;

    span:last-child {
        a:last-child {
            font-weight: bold;
            color: black;
        }
    }

    span:only-child {
        font-weight: bold;
        color: black;
    }
}

.greenRow {
    color: #59a23f;
}

.redRow {
    color: #ff4557;
}

.doubleRow {
    height: 61px;
}

.paperWrapper {
    display: inline-flex;
    width: 100%;
    margin-bottom: 38px;

    .paper {
        display: grid;
        background: #ffffff;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        width: 13%;
        height: 100px;
        margin: auto;

        span {
            margin: auto;

            &.title {
                font-size: 14px;
                font-weight: 600;
                padding-top: 10px;
            }

            &.number {
                font-size: 28px;
                font-weight: 600;
            }
        }
    }

    div:first-child {
        margin-left: 0;
    }

    div:last-child {
        margin-right: 0;
    }
}

.pagination {
    margin: 16px 0;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    right: 50px;
}
