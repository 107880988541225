@import 'src/styles/mixins';

:global(.sidebarMenuItem) {
    padding-left: 16px !important;

    display: flex;
    align-items: center;

    @include osNormal;
    font-size: 14px;
    line-height: 140%;
}

:global(.sidebarMenuItem__icon) {
    min-height: 24px !important;
    min-width: 24px !important;
}
