@import 'src/styles/mixins';
@import 'src/styles/variables';

.title {
    cursor: pointer;
    transition: background-color 0.3s $cubic, color 0.3s $cubic;

    color: $grayDarkColor;
    @include osNormal;
    font-size: 14px;
    line-height: 140%;

    @include hover {
        color: $blueForLight;
    }

    @include active {
        background-color: $buttonActive;
        color: $white;
    }

    padding: 14px 0 14px 57.5px;
}

:global(.ant-layout-sider-collapsed.sidebar) {
    .title {
        padding: 15px 0 14px 20px;
    }
}

.titleActive {
    color: $blueForLight;
}
