@import './src/styles/variables';

.spectrumField {
    padding: 12px 8px !important;
    width: 100% !important;
    border: 1px solid $grayBorderFields;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    &::-moz-placeholder {
        color: $grayDark;
    }
    &::-webkit-input-placeholder {
        color: $grayDark;
    }

    border-radius: 4px;
    position: relative;
    &:focus {
        border-radius: 2pt;
        box-shadow: 0 0 0 1pt $blueForLight;
        outline: none;
        transition: 0.1s;
    }
}

.supportTextField {
    background: inherit !important;
}

.form {
    &__item {
        margin-bottom: 12px !important;
    }
}

.formComponent {
    .ant-form-item-explain,
    .ant-form-item-explain-error,
    .ant-form-item-explain-warning {
        font-size: 12px !important;
        margin-bottom: 0;
        margin-top: 0;
        color: $redError !important;
    }

    .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    .ant-form-item-has-error
        :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
    .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
    .ant-form-item-has-error
        :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
        background-color: #fff;
        border-color: $redError;
    }

    .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: none !important;
        outline: none !important;
    }

    .ant-select {
        padding: 0px !important;
    }

    .ant-select-selector,
    .ant-select-selection-search-input {
        height: 46px !important;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}
