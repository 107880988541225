@import '../../styles/variables';
@import '../../styles/mixins';

.AppHeader {
    background-color: $white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-height: 58px;
}

.searchBarWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.collapseIconWrapper {
    position: absolute;
    width: 24px;
    height: 24px;
    cursor: pointer;
    left: 15px;

    .collapseIcon {
        width: 100%;
        height: 100%;
        object-fit: contain;

        path {
            stroke: #dfe0eb;
            transition: stroke 0.3s $cubic;
        }
    }

    @include hover {
        .collapseIcon {
            path {
                stroke: black;
            }
        }
    }
}

.selectForm {
    flex-grow: 1;
    @include osNormal;
    font-size: 12px;
    line-height: 140%;

    &::placeholder {
        background-color: red;
    }
}

.searchIconWrapper {
    width: 17px;
    height: 17px;
}

.searchIcon {
    width: 100%;
    height: 100%;

    object-fit: contain;
}

.headerProfileBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 18px;
}

.headerNotifications {
    min-height: 32px;
    min-width: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    @include osBold;
    font-size: 12px;
    color: $white;
}

.splitLine {
    border-radius: 1px;
    background-color: $grayBorderFields;
    height: 18px;
    width: 1px;
}

.profileBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 18px;
}

.profileBlockName {
    white-space: nowrap;
    font-size: 14px;
    line-height: 140%;
    @include osNormal;
}

.avatar {
    width: 100% !important;
}

:global(.hidePreview) {
    :global(.ant-image-mask) {
        font-size: 0;
    }
}
