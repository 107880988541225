@import '../../styles/variables';
@import '../../styles/mixins';

.content {
    padding-left: 35px;
    padding-right: 34px;
    font-family: 'Open Sans' !important;
}

.header {
    padding: 32px 0 24px 0 !important;
}

.modalHeader {
    display: flex;
    margin-bottom: 34px;

    .title {
        font-size: 18px;
        margin-bottom: 0;
    }

    .closeBtn {
        margin-left: auto;
        border: none;
        font-size: 18px;
    }
}

.fileForm {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

.tableWrapper {
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.files {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    width: 100%;

    .file {
        cursor: pointer;
        height: 46px;
        display: inline-flex;
        border: none;
        border-radius: 8px;
        background: #eeeff4;
        margin-bottom: 14px;

        span {
            margin: auto;
            padding-right: 11px;
            font-family: 'Open Sans';
            font-size: 14px;
            color: #7a7b84;
            white-space: break-spaces;
            max-width: 140px;
            max-height: 40px;
            overflow: hidden;
        }

        span:first-child {
            margin: auto;
            padding-right: 8px;
            font-size: 30px;
        }

        span:last-child {
            margin: auto;
            padding-right: 4px;
        }
    }
}

.title {
    font-family: 'Open Sans', sans-serif, Verdana, Geneva, Tahoma !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 140% !important;
    color: #7a7b84 !important;

    span:last-child {
        a:last-child {
            font-weight: bold !important;
            color: black !important;
        }
    }

    span:only-child {
        font-weight: bold !important;
        color: black !important;
    }
}

textarea {
    min-height: 150px;
}
