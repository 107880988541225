$white: #ffffff;
$blueForLight: #1268b3;
$blueForActive: #1271be;
$blueForFocus: #1271be;
$grayLight: #f7f8fc;
$grayBorderFields: #dfe0eb;
$grayDark: #7a7b84;
$grayDark2: #363740;
$grayDarkColor: #363740;
$redForLight: #ff4456;
$redForActive: #f64051;
$redForFocus: #f84051;
$redError: #ff4d4f;

$buttonActive: #a6b8ff;

$cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
