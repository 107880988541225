@import 'src/styles/variables';

.footer {
    width: 100%;
    height: 58px;
    background-color: $white;
    position: fixed;
    bottom: 0;
    box-shadow: 0 0 20px 0 #0000000f;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 14px !important;
    &__item {
        align-items: center;
        font-weight: 600;
        font-family: 'Open Sans', sans-serif;
        color: $grayDarkColor;
        display: flex;
    }
}

.footerGroup {
    width: 350px;
    &__element {
        display: flex;
        margin-right: 15px;
        &_lang {
            cursor: pointer;
        }
    }
}
