.file {
    position: relative;
    width: 122px;
    height: 80px;
    margin-right: 16px;
    border: 1px solid #dfe0eb;
    border-radius: 4px;
    background: #ffffff;
    padding: 8px;
    cursor: pointer;
    transition: all 0.3s;

    .icon {
        font-size: 24px;
    }

    p {
        line-height: 16px;
        font-size: 12px;
        max-height: 32px;
        overflow: hidden;
    }

    .closeBtn {
        position: absolute;
        right: 8px;
        top: 8px;
        width: 8px;
        font-size: 8px;
        height: 8px;

        span {
            font-size: 8px !important;
        }

        &:hover {
            path {
                stroke: black;
            }
        }
    }

    &:hover {
        background: #f5f5f5;
    }
}
