@import './src/styles/variables';

.textRestore {
    font-size: 12px;
    color: $grayDark2;
    margin: 16px 0 0;
    text-align: center;
    text-decoration: none;
    display: block;
}

.formContainer {
    margin-top: 60px;
}
