@import '../../styles/mixins';
@import '../../styles/variables';

.mainPage {
    min-height: 100vh;
}

.sidebar {
    background-color: white;
    padding: 19.5px 0 29px 0;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    z-index: 1;

    :global(.ant-layout-sider-children) {
        display: flex;
        flex-direction: column;
    }
}

.logoContainer {
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
}

.logoWrapper {
    min-width: 44px;
    height: 44px;
}

.logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

:global(.ant-layout-sider-collapsed).sidebar {
    .logoDescription {
        transform: translateX(3px);
        opacity: 0;
        transition: transform 0.3s, opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .menuDivider {
        margin: 0 25px !important;
    }
}

.logoDescription {
    display: flex;
    flex-direction: column;
    opacity: 1;
    transition: transform 0.3s, opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    //@media screen and (max-width: 1024px)  {
    //  transform: translateX(3px);
    //  opacity: 0;
    //  transition: transform 0.3s, opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    //}
}

.logoDescriptionTopHead {
    color: $grayDarkColor;
    font-family: 'TT Bricks';
    font-size: 21px;
    margin-bottom: 0;
    height: 26px;
}

.logoDescriptionBottomHead {
    margin: 0;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif, Verdana, Geneva, Tahoma;
    font-size: 13px;
    font-weight: 800;
}

.menuWrapper {
    flex-grow: 1;
    margin-top: 94.5px;
}

.menuDivider {
    margin: 0 20px !important;

    //@media screen and (max-width: 1024px) {
    //  margin: 0 25px !important;
    //}
}

:global(.MainPageLayout) {
    position: relative;
    width: 100%;
    height: 100%;
}
