@import './src/styles/variables';

.steps {
    font-size: 12px;
    color: $grayDark2;
    font-weight: 600;
    margin: 24px 0 0;
    text-align: right;
}

.formContainer {
    margin-top: 16px;
}
